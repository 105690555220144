import React from 'react'

function IconSVG() {
	return (
    <svg
      width="109"
      height="100"
      viewBox="0 0 109 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="dc-svg"
    >
      <g clipPath="url(#clip0_2466_7)">
        <path
          d="M8.85634 98.014C12.9447 97.8812 15.9253 87.4957 15.5136 74.8172C15.1018 62.1388 11.4537 51.9686 7.36536 52.1013C3.27696 52.2341 0.296446 62.6196 0.708172 75.298C1.1199 87.9765 4.76796 98.1467 8.85634 98.014Z"
          fill="#FB8A51"
        />
        <path
          d="M15.625 80.3433C15.1539 90.3802 12.4802 97.8946 9.00651 98.0078C7.50783 98.0565 5.95783 96.6157 5.47888 95.3091C7.80125 94.5315 9.52495 93.4144 10.8986 91.3025C13.017 88.0499 14.8026 84.142 15.625 80.3433Z"
          fill="#DC7948"
        />
        <path
          d="M8.85634 98.014C12.9447 97.8812 15.9253 87.4957 15.5136 74.8172C15.1018 62.1388 11.4537 51.9686 7.36536 52.1013C3.27696 52.2341 0.296446 62.6196 0.708172 75.298C1.1199 87.9765 4.76796 98.1467 8.85634 98.014Z"
          stroke="#314E5D"
          strokeMiterlimit="10"
        />
        <path
          d="M5.8716 79.8459C7.31695 79.7991 8.38536 76.5804 8.25794 72.6569C8.13053 68.7333 6.85554 65.5908 5.41019 65.6377C3.96485 65.6846 2.89644 68.9033 3.02386 72.8269C3.15127 76.7503 4.42625 79.8929 5.8716 79.8459Z"
          fill="#FFC4A5"
        />
        <path
          d="M5.96356 82.6863C6.41751 82.6716 6.77355 82.2916 6.75881 81.8376C6.74406 81.3837 6.36413 81.0276 5.91018 81.0424C5.45625 81.0571 5.10019 81.4371 5.11494 81.8911C5.12968 82.345 5.50963 82.7011 5.96356 82.6863Z"
          fill="#FFC4A5"
        />
        <path
          d="M10.3408 58.7553C11.8724 61.2645 12.2237 64.0079 12.55 66.8737C12.8842 69.8092 13.1434 72.7605 12.8513 75.7105C12.8263 75.9632 13.2211 75.9619 13.2461 75.7105C13.5553 72.5855 13.2461 69.4619 12.8855 66.3553C12.5658 63.5921 12.1539 60.9671 10.6816 58.5566C10.55 58.3395 10.2079 58.5382 10.3408 58.7553Z"
          fill="#314E5D"
        />
        <path
          d="M1.04478 46.8053C1.33294 41.1632 9.0711 31.3724 9.0711 31.3724C9.0711 31.3724 15.729 41.8987 15.4409 47.5408C15.1527 53.1829 11.4132 55.0526 7.43952 54.85C3.46583 54.6474 0.756619 52.4474 1.04478 46.8053Z"
          fill="#BC7947"
        />
        <path
          d="M9.06847 31.3724C9.06847 31.3724 8.73295 31.7961 8.20137 32.5171C10.0172 35.6434 13.8725 42.8329 13.6527 47.1553C13.3646 52.7974 9.629 54.6671 5.654 54.4645C5.44084 54.454 5.23163 54.4355 5.02637 54.4132C5.80642 54.6707 6.6187 54.8176 7.43953 54.85C11.4145 55.0526 15.1501 53.1829 15.4382 47.5408C15.7264 41.8987 9.06847 31.3724 9.06847 31.3724Z"
          fill="#9D653D"
        />
        <path
          d="M9.04867 31.3708L3.52893 39.6603C3.52893 39.6603 5.26314 43.4366 9.68551 41.3866C9.68551 41.3866 12.146 39.6866 14.6421 42.8735C14.6421 42.8735 13.704 39.963 9.04867 31.3708Z"
          fill="#42EFE2"
        />
        <path
          d="M6.54086 39.1915C6.97528 38.5919 7.12326 37.9578 6.87138 37.7753C6.61951 37.5929 6.06315 37.9311 5.62873 38.5308C5.19432 39.1304 5.04634 39.7645 5.29822 39.947C5.5501 40.1295 6.10645 39.7912 6.54086 39.1915Z"
          fill="#FCFCFC"
        />
        <path
          d="M7.72396 37.1724C7.92901 36.8893 7.99887 36.59 7.88 36.504C7.76112 36.4178 7.49853 36.5775 7.29346 36.8605C7.08841 37.1436 7.01854 37.4429 7.13742 37.529C7.25629 37.6151 7.51889 37.4554 7.72396 37.1724Z"
          fill="#FCFCFC"
        />
        <path
          d="M3.17108 40.0971C3.44342 40.6508 3.82429 41.1442 4.29105 41.5479C4.7578 41.9516 5.30095 42.2574 5.88818 42.4471C7.07239 42.8142 8.32371 42.6116 9.45529 42.159C9.68818 42.0656 9.8895 41.9498 10.1132 41.8432C10.1658 41.8182 10.2198 41.7958 10.2737 41.7721C10.2921 41.7642 10.3908 41.7274 10.3106 41.7563L10.4237 41.7182C10.599 41.6631 10.7786 41.6231 10.9606 41.5984C12.3158 41.4142 13.5184 42.3537 14.3408 43.3906C14.6513 43.7853 15.225 43.259 14.9158 42.8708C14.079 41.8221 12.9566 40.9208 11.579 40.8142C10.8857 40.7613 10.192 40.9117 9.58292 41.2471C8.70908 41.7352 7.70634 41.9427 6.71055 41.8419C5.91363 41.7316 5.17635 41.3583 4.61582 40.7813C4.48882 40.6528 4.36933 40.517 4.25792 40.3748C4.21187 40.3156 4.16713 40.255 4.12634 40.1945C4.08555 40.134 4.15792 40.2445 4.1066 40.1656L4.05924 40.0932C3.98443 39.979 3.9176 39.8599 3.85924 39.7366C3.65003 39.284 2.96187 39.6406 3.17108 40.0932V40.0971Z"
          fill="#314E5D"
        />
        <path
          d="M1.17234 46.7514C1.4605 41.1093 9.19866 31.3185 9.19866 31.3185C9.19866 31.3185 15.8566 41.8448 15.5684 47.4869C15.2802 53.129 11.5408 55.0001 7.56708 54.7961C3.59339 54.5922 0.884183 52.3935 1.17234 46.7514Z"
          stroke="#314E5D"
          strokeMiterlimit="10"
        />
        <path
          d="M7.1673 46.3374C7.37145 44.7827 7.07272 43.4615 6.50006 43.3862C5.92739 43.3111 5.29766 44.5104 5.09352 46.065C4.88936 47.6198 5.1881 48.9411 5.76077 49.0162C6.33343 49.0915 6.96315 47.8921 7.1673 46.3374Z"
          fill="#D0A07B"
        />
        <path
          d="M12.4498 50.8853L3.06752 50.99C1.65057 51.006 0.51473 52.1674 0.530559 53.5844L0.538422 54.2883C0.55425 55.7053 1.71576 56.8411 3.13271 56.8253L12.515 56.7204C13.932 56.7046 15.0678 55.5431 15.052 54.1261L15.0441 53.4223C15.0283 52.0053 13.8668 50.8695 12.4498 50.8853Z"
          fill="#D8D9D9"
        />
        <path
          d="M10.8039 51.5646L4.35657 51.6357C4.35966 51.9187 4.41848 52.1983 4.52965 52.4586C4.64083 52.719 4.8022 52.9548 5.00453 53.1528C5.20687 53.3507 5.44621 53.5069 5.70891 53.6123C5.97159 53.7177 6.25247 53.7703 6.53551 53.7673L8.67762 53.7436C9.24832 53.736 9.7927 53.5023 10.1913 53.0937C10.5899 52.6853 10.8103 52.1353 10.8039 51.5646Z"
          fill="white"
        />
        <path
          d="M3.12393 56.8255L12.5062 56.7207C13.9232 56.7049 15.059 55.5433 15.0432 54.1265L15.0353 53.4225C15.0195 52.0055 13.8581 50.8697 12.441 50.8855L3.05874 50.9903C1.64178 51.0062 0.505942 52.1676 0.52177 53.5846L0.529633 54.2886C0.545462 55.7055 1.70697 56.8413 3.12393 56.8255Z"
          stroke="#314E5D"
          strokeMiterlimit="10"
        />
        <path
          d="M3.76189 49.6051C3.57258 48.9458 3.55329 48.2492 3.70583 47.5804C3.85838 46.9115 4.17778 46.2922 4.63426 45.7801C4.80268 45.5907 4.52374 45.3104 4.35531 45.5012C3.85059 46.0636 3.49659 46.7447 3.32633 47.4811C3.15606 48.2174 3.17509 48.9847 3.38163 49.7117C3.39678 49.7608 3.43045 49.802 3.47549 49.8266C3.52053 49.8512 3.57338 49.8574 3.62285 49.8437C3.67233 49.83 3.71451 49.7975 3.74047 49.7532C3.76643 49.709 3.77412 49.6563 3.76189 49.6065V49.6051Z"
          fill="#314E5D"
        />
        <path
          d="M4.50265 48.1645C4.44878 47.891 4.47137 47.6078 4.56794 47.3461C4.66451 47.0845 4.83131 46.8546 5.05002 46.6816C5.24607 46.5198 4.96579 46.2421 4.77106 46.4027C4.50298 46.6252 4.30083 46.9167 4.1865 47.2458C4.07218 47.5749 4.04999 47.929 4.12239 48.2698C4.17239 48.5185 4.55265 48.4132 4.50265 48.1645Z"
          fill="#314E5D"
        />
        <path
          d="M11.6514 48.6844C12.0839 48.3183 12.3718 47.81 12.4635 47.2508C12.5551 46.6916 12.4444 46.1181 12.1514 45.6331C12.0198 45.416 11.679 45.6147 11.8106 45.8318C12.0598 46.2414 12.1526 46.7272 12.0721 47.1998C11.9916 47.6724 11.7432 48.1002 11.3724 48.4041C11.1777 48.5673 11.4579 48.8449 11.6514 48.6831V48.6844Z"
          fill="#314E5D"
        />
        <path
          d="M90.6672 41.7354L95.9869 37.763C96.5343 37.3553 97.2211 37.1817 97.8965 37.2804C98.5719 37.3791 99.1805 37.742 99.5882 38.2894L107.7 49.1446C108.108 49.692 108.281 50.3788 108.183 51.0542C108.084 51.7296 107.721 52.3382 107.174 52.7459L90.2001 65.4262C89.6822 65.8136 89.3393 66.3906 89.2468 67.0307C89.1543 67.6707 89.3197 68.3212 89.7066 68.8394"
          stroke="#314E5D"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M55.3685 -0.000671387L29.9868 20.3921L65.6926 64.8331L91.0744 44.4402L55.3685 -0.000671387Z"
          fill="#FFE48D"
        />
        <path
          d="M107.35 85.8341L101.579 90.142C101.119 90.4844 100.543 90.6307 99.9756 90.5489C99.4083 90.4669 98.8966 90.1636 98.5527 89.7052L88.0816 73.2736C87.7393 72.814 87.593 72.2374 87.6748 71.6702C87.7566 71.1029 88.0601 70.5912 88.5185 70.2473L91.9737 67.667C92.4333 67.3245 93.0099 67.1782 93.5772 67.2602C94.1444 67.342 94.6561 67.6454 95.0001 68.1039L107.787 82.8118C108.128 83.2711 108.274 83.8468 108.192 84.4129C108.11 84.9793 107.807 85.4902 107.35 85.8341Z"
          fill="#F65020"
        />
        <path
          d="M100.75 88.4079L90.2698 71.9737C89.9273 71.5141 89.7811 70.9375 89.8629 70.3703C89.9448 69.803 90.2482 69.2913 90.7066 68.9474L88.6843 70.4684C88.2258 70.8124 87.9224 71.3241 87.8406 71.8913C87.7587 72.4586 87.9049 73.0352 88.2474 73.4948L98.7237 89.9224C99.0677 90.3808 99.5794 90.6842 100.147 90.7661C100.714 90.8479 101.29 90.7016 101.75 90.3592L103.776 88.8448C103.317 89.1871 102.74 89.3334 102.173 89.2516C101.606 89.1698 101.094 88.8663 100.75 88.4079Z"
          fill="#FF7B52"
        />
        <path
          d="M82.1457 40.9539L64.3552 54.2485C61.7549 56.1916 61.2222 59.8748 63.1653 62.475L64.131 63.7673C66.0742 66.3675 69.7573 66.9003 72.3576 64.9571L90.1482 51.6625C92.7485 49.7195 93.2813 46.0362 91.3381 43.436L90.3724 42.1437C88.4293 39.5435 84.7461 39.0107 82.1457 40.9539Z"
          fill="#FFE48D"
        />
        <path
          d="M90.079 49.1274L70.2303 63.9576C69.2531 64.684 68.0279 64.9937 66.8228 64.8191C65.6178 64.6445 64.531 63.9999 63.8 63.0261L64.5027 63.9668C65.2337 64.94 66.3204 65.5842 67.5252 65.7586C68.7299 65.9329 69.9547 65.6232 70.9316 64.8971L90.7816 50.0682C91.7547 49.3368 92.3986 48.25 92.5727 47.0454C92.7468 45.8407 92.4368 44.6161 91.7106 43.6392L91.0079 42.6984C91.7341 43.6753 92.0441 44.8999 91.87 46.1046C91.696 47.3092 91.052 48.3961 90.079 49.1274Z"
          fill="#F9C441"
        />
        <path
          d="M83.0041 40.4318L63.5001 55.0002C61.5119 56.4857 61.1212 59.3581 62.6343 61.3818L62.8975 61.7331C62.615 60.8412 62.615 59.8839 62.8977 58.9921C63.1804 58.1003 63.7318 57.3177 64.4764 56.7515L83.9804 42.1831C85.9685 40.6975 88.833 41.137 90.3462 43.1607L92.583 46.1594C92.4266 45.6553 92.1848 45.1819 91.8685 44.7594L89.3685 41.4094C87.8567 39.3857 84.9922 38.9475 83.0041 40.4318Z"
          fill="#FFF2CF"
        />
        <path
          d="M64.1936 62.4028C72.7633 56.0015 81.4001 49.6949 89.9699 43.2896C90.4817 42.9081 90.7436 42.266 90.3949 41.6699C90.1001 41.166 89.2896 40.8607 88.7751 41.2449C80.2067 47.6462 71.5673 53.9528 62.9988 60.3541C62.487 60.7357 62.2251 61.3778 62.5738 61.9738C62.8686 62.4778 63.6791 62.7831 64.1936 62.3988V62.4028Z"
          fill="#FFF2CF"
        />
        <path
          d="M82.0914 40.5199L64.324 53.8454C61.7272 55.7932 61.2009 59.4773 63.1485 62.0741L64.1164 63.3646C66.064 65.9616 69.7482 66.4879 72.3451 64.5402L90.1124 51.2146C92.7093 49.267 93.2356 45.5829 91.288 42.9859L90.3201 41.6954C88.3724 39.0986 84.6882 38.5723 82.0914 40.5199Z"
          stroke="#314E5D"
          strokeMiterlimit="10"
        />
        <path
          d="M107.296 85.7618L101.53 90.071C101.071 90.4133 100.494 90.5597 99.9268 90.4778C99.3596 90.396 98.8479 90.0926 98.5039 89.6341L88.0263 73.2012C87.6838 72.7416 87.5375 72.1651 87.6194 71.5978C87.7013 71.0306 88.0047 70.5189 88.4631 70.1749L91.9223 67.5894C92.3819 67.2469 92.9585 67.1007 93.5257 67.1826C94.093 67.2644 94.6046 67.5678 94.9486 68.0262L107.735 82.7341C108.078 83.1941 108.224 83.7712 108.142 84.3387C108.059 84.9064 107.755 85.4181 107.296 85.7618Z"
          stroke="#314E5D"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M102.738 80.8567C103.199 81.5165 103.71 82.1401 104.266 82.7225C104.536 83.0199 104.811 83.3173 105.055 83.6436C105.195 83.8291 105.384 84.0094 105.258 84.2673C105.182 84.4225 104.863 84.5646 104.722 84.6462C104.065 85.0409 104.665 86.062 105.32 85.6686C106.287 85.0883 106.918 84.2949 106.211 83.2291C105.503 82.1633 104.474 81.3317 103.761 80.2633C103.341 79.6304 102.313 80.2238 102.738 80.8607V80.8567Z"
          fill="white"
        />
        <path
          d="M71.6303 48.1986V94.442C71.6303 95.8344 71.0772 97.1697 70.0925 98.1543C69.1081 99.1389 67.7727 99.692 66.3803 99.692H25.3882C23.9958 99.692 22.6604 99.1389 21.6758 98.1543C20.6914 97.1697 20.1382 95.8344 20.1382 94.442V64.2657C20.0279 64.2819 19.9168 64.2902 19.8053 64.2907C19.2166 64.2907 18.652 64.0568 18.2357 63.6405C17.8194 63.2243 17.5856 62.6597 17.5856 62.071C17.5856 61.4822 17.8194 60.9176 18.2357 60.5014C18.652 60.0851 19.2166 59.8512 19.8053 59.8512C19.9168 59.8516 20.0279 59.8599 20.1382 59.8762V48.1986H71.6303Z"
          fill="#E8F0F9"
        />
        <path
          d="M72.4921 43.6487H18.1645C17.2924 43.6487 16.5856 44.3557 16.5856 45.2277V46.2869C16.5856 47.159 17.2924 47.8658 18.1645 47.8658H72.4921C73.3641 47.8658 74.0711 47.159 74.0711 46.2869V45.2277C74.0711 44.3557 73.3641 43.6487 72.4921 43.6487Z"
          fill="#B8F9F5"
        />
        <path
          d="M57.8525 60.8711C57.821 62.5434 58.7999 64.175 60.3525 64.8474C60.8407 65.0592 61.4052 64.9921 61.7025 64.4934C61.946 64.0763 61.8341 63.3566 61.3486 63.1434C60.4039 62.7342 59.8104 61.9171 59.8302 60.8711C59.8552 59.6 57.8815 59.6 57.8565 60.8711H57.8525Z"
          fill="#F2F0E7"
        />
        <path
          d="M53.3672 48.5327C53.2541 51.8221 53.1396 55.1379 53.5409 58.4156C53.6725 59.5077 53.7514 61.3208 55.0133 61.7998C56.083 62.2064 56.8554 61.1419 57.1186 60.2314C57.6646 58.3432 57.308 56.284 57.608 54.3603C57.6883 53.8419 57.8514 53.2708 58.3067 53.0077C58.7212 52.7682 59.2659 52.8761 59.662 53.1393C60.4857 53.6945 60.7449 54.7958 60.7343 55.7893C60.7001 58.9471 60.6304 62.1129 60.5962 65.2774C60.5843 66.4037 60.7199 67.7774 61.7436 68.2458C62.633 68.6537 63.7172 68.0471 64.1896 67.1932C64.662 66.3393 64.6659 65.3077 64.6633 64.33L64.6225 48.0932"
          fill="#FFE48D"
        />
        <path
          d="M41.2895 39.7629C43.9338 39.7629 46.0777 37.6192 46.0777 34.9747C46.0777 32.3303 43.9338 30.1866 41.2895 30.1866C38.645 30.1866 36.5013 32.3303 36.5013 34.9747C36.5013 37.6192 38.645 39.7629 41.2895 39.7629Z"
          fill="#FFE48D"
        />
        <path
          d="M41.2895 30.1866C40.2869 30.1851 39.3093 30.5001 38.4961 31.0866H38.5961C39.6096 31.0859 40.5973 31.407 41.4168 32.0037C42.2362 32.6003 42.845 33.4416 43.1557 34.4065C43.4664 35.3712 43.4627 36.4097 43.1453 37.3724C42.8278 38.335 42.2131 39.1721 41.3895 39.7629C42.0181 39.7563 42.6393 39.6261 43.2175 39.3794C43.7958 39.1328 44.3198 38.7746 44.7596 38.3255C45.1995 37.8763 45.5466 37.345 45.7811 36.7617C46.0156 36.1784 46.1329 35.5546 46.1264 34.9261C46.1198 34.2975 45.9895 33.6763 45.7428 33.098C45.4962 32.5197 45.1381 31.9958 44.689 31.5559C44.2398 31.1161 43.7085 30.769 43.1252 30.5345C42.5419 30.3 41.9181 30.1826 41.2895 30.1892V30.1866Z"
          fill="#F6C240"
        />
        <path
          d="M41.3644 40.1382C44.0089 40.1382 46.1526 37.9944 46.1526 35.35C46.1526 32.7056 44.0089 30.5619 41.3644 30.5619C38.72 30.5619 36.5763 32.7056 36.5763 35.35C36.5763 37.9944 38.72 40.1382 41.3644 40.1382Z"
          stroke="#314E5D"
          strokeMiterlimit="10"
        />
        <path
          d="M72.5184 43.2051H67.6316C67.9845 43.2062 68.3225 43.3468 68.572 43.5963C68.8215 43.8458 68.9621 44.184 68.9632 44.5367V46.0907C68.9621 46.4436 68.8215 46.7816 68.572 47.0312C68.3225 47.2807 67.9845 47.4212 67.6316 47.4222H72.5145C72.8674 47.4212 73.2054 47.2807 73.4549 47.0312C73.7044 46.7816 73.845 46.4436 73.8461 46.0907V44.5367C73.845 44.1846 73.705 43.8471 73.4563 43.5978C73.2076 43.3483 72.8705 43.2072 72.5184 43.2051Z"
          fill="#3CD8D6"
        />
        <path
          d="M23.2448 46.3158V44.7592C23.244 44.4053 23.3839 44.0654 23.6336 43.8146C23.8834 43.5637 24.2225 43.4221 24.5764 43.4211H18.1396C17.7867 43.4221 17.4486 43.5628 17.1992 43.8123C16.9496 44.0617 16.809 44.3998 16.808 44.7527V46.3158C16.809 46.6687 16.9496 47.0067 17.1992 47.2562C17.4486 47.5057 17.7867 47.6463 18.1396 47.6474H24.5764C24.2235 47.6463 23.8855 47.5057 23.636 47.2562C23.3864 47.0067 23.2459 46.6687 23.2448 46.3158Z"
          fill="#42EFE2"
        />
        <path
          d="M23.9106 93.9975V63.816C23.8003 63.8323 23.6892 63.8406 23.5777 63.841C22.989 63.841 22.4244 63.6071 22.0081 63.1908C21.5918 62.7746 21.358 62.21 21.358 61.6212C21.358 61.0325 21.5918 60.4679 22.0081 60.0517C22.4244 59.6354 22.989 59.4015 23.5777 59.4015C23.6892 59.402 23.8003 59.4103 23.9106 59.4265V47.7554H19.6935V59.4331C19.5832 59.4169 19.4721 59.4086 19.3606 59.4081C18.7719 59.4081 18.2073 59.642 17.791 60.0583C17.3747 60.4745 17.1409 61.0391 17.1409 61.6278C17.1409 62.2166 17.3747 62.7812 17.791 63.1974C18.2073 63.6137 18.7719 63.8475 19.3606 63.8475C19.4721 63.8471 19.5832 63.8389 19.6935 63.8225V93.9975C19.6935 95.39 20.2467 96.7253 21.2311 97.7099C22.2157 98.6945 23.5511 99.2475 24.9435 99.2475H29.1606C27.7682 99.2475 26.4328 98.6945 25.4482 97.7099C24.4638 96.7253 23.9106 95.39 23.9106 93.9975Z"
          fill="white"
        />
        <path
          d="M25.3408 48.5316V96.2514C25.3408 97.6987 27.5856 97.6987 27.5856 96.2514V48.5316C27.5856 47.0843 25.3408 47.0843 25.3408 48.5316Z"
          fill="white"
        />
        <path
          d="M17.3685 62.0708C17.3685 65.1695 17.3685 68.2682 17.3487 71.3655C17.3145 76.1971 19.9435 80.7682 24.3119 82.9629C28.1724 84.9024 32.9027 84.7563 36.7645 82.8998C40.4066 81.1498 42.6224 76.6576 42.6224 72.73V62.2997"
          stroke="#314E5D"
          strokeMiterlimit="10"
        />
        <path
          d="M66.304 48.4211V94.6645C66.304 96.0569 65.7508 97.3923 64.7662 98.3769C63.7817 99.3613 62.4463 99.9145 61.054 99.9145H65.9368C67.3292 99.9145 68.6646 99.3613 69.6491 98.3769C70.6337 97.3923 71.1869 96.0569 71.1869 94.6645V48.4211H66.304Z"
          fill="#BCD9EA"
        />
        <path
          d="M51.3146 28.391C52.6364 28.391 53.708 27.3194 53.708 25.9975C53.708 24.6757 52.6364 23.6041 51.3146 23.6041C49.9927 23.6041 48.9211 24.6757 48.9211 25.9975C48.9211 27.3194 49.9927 28.391 51.3146 28.391Z"
          fill="#FFE48D"
        />
        <path
          d="M51.3157 23.6041C50.8145 23.6036 50.3261 23.761 49.9197 24.0541H49.9697C50.4774 24.0527 50.9724 24.2129 51.3832 24.5115C51.794 24.81 52.0992 25.2315 52.2545 25.7149C52.4101 26.1983 52.4077 26.7187 52.2478 27.2007C52.0881 27.6827 51.7792 28.1014 51.3657 28.3962C52.0005 28.3895 52.6066 28.131 53.0507 27.6775C53.4949 27.224 53.7407 26.6125 53.7341 25.9778C53.7274 25.3429 53.4689 24.7369 53.0155 24.2927C52.5619 23.8485 51.9505 23.6027 51.3157 23.6094V23.6041Z"
          fill="#F6C240"
        />
        <path
          d="M50.6422 29.2142C52.4604 29.2142 53.9343 27.7403 53.9343 25.9221C53.9343 24.104 52.4604 22.63 50.6422 22.63C48.824 22.63 47.3501 24.104 47.3501 25.9221C47.3501 27.7403 48.824 29.2142 50.6422 29.2142Z"
          stroke="#314E5D"
          strokeMiterlimit="10"
        />
        <path
          d="M44.1008 18.1664C44.1305 16.6787 42.9487 15.4487 41.4612 15.419C39.9737 15.3893 38.7437 16.5711 38.7139 18.0586C38.6842 19.5461 39.8659 20.7761 41.3534 20.8058C42.841 20.8356 44.071 19.6539 44.1008 18.1664Z"
          fill="#FFE48D"
        />
        <path
          d="M41.3369 15.3752C40.7732 15.3648 40.2207 15.5324 39.7579 15.8541H39.8145C40.3842 15.8642 40.9361 16.0548 41.3907 16.3982C41.8453 16.7417 42.1792 17.2207 42.3445 17.7659C42.5098 18.3113 42.4978 18.895 42.3103 19.4332C42.1229 19.9712 41.7696 20.4361 41.3014 20.7607C42.0058 20.7507 42.6783 20.465 43.1746 19.965C43.671 19.465 43.9516 18.7905 43.9565 18.0859C43.9614 17.3815 43.6899 16.703 43.2004 16.1963C42.711 15.6896 42.0424 15.3948 41.3382 15.3752H41.3369Z"
          fill="#F6C240"
        />
        <path
          d="M41.2896 20.6856C42.7356 20.6856 43.908 19.5133 43.908 18.0672C43.908 16.6211 42.7356 15.4487 41.2896 15.4487C39.8434 15.4487 38.6711 16.6211 38.6711 18.0672C38.6711 19.5133 39.8434 20.6856 41.2896 20.6856Z"
          stroke="#314E5D"
          strokeMiterlimit="10"
        />
        <path
          d="M42.4988 64.2354C44.2152 64.2354 45.6067 62.844 45.6067 61.1275C45.6067 59.4111 44.2152 58.0197 42.4988 58.0197C40.7823 58.0197 39.3909 59.4111 39.3909 61.1275C39.3909 62.844 40.7823 64.2354 42.4988 64.2354Z"
          fill="#B8F9F5"
        />
        <path
          d="M42.7211 64.0129C44.4375 64.0129 45.8289 62.6215 45.8289 60.905C45.8289 59.1886 44.4375 57.7971 42.7211 57.7971C41.0046 57.7971 39.6132 59.1886 39.6132 60.905C39.6132 62.6215 41.0046 64.0129 42.7211 64.0129Z"
          stroke="#314E5D"
          strokeMiterlimit="10"
        />
        <path
          d="M71.3527 47.921V94.1644C71.3527 95.5567 70.7996 96.8921 69.8151 97.8767C68.8305 98.8612 67.4951 99.4144 66.1027 99.4144H25.1106C23.7182 99.4144 22.3829 98.8612 21.3982 97.8767C20.4138 96.8921 19.8606 95.5567 19.8606 94.1644V63.9881C19.7504 64.0042 19.6392 64.0127 19.5277 64.0131C18.939 64.0131 18.3744 63.7793 17.9581 63.3629C17.5418 62.9466 17.308 62.382 17.308 61.7933C17.308 61.2046 17.5418 60.64 17.9581 60.2237C18.3744 59.8075 18.939 59.5736 19.5277 59.5736C19.6392 59.5741 19.7504 59.5824 19.8606 59.5986V47.921H71.3527Z"
          stroke="#314E5D"
          strokeMiterlimit="10"
        />
        <path
          d="M72.4368 43.5933H18.1092C17.2371 43.5933 16.5303 44.3002 16.5303 45.1723V46.2315C16.5303 47.1035 17.2371 47.8104 18.1092 47.8104H72.4368C73.3088 47.8104 74.0158 47.1035 74.0158 46.2315V45.1723C74.0158 44.3002 73.3088 43.5933 72.4368 43.5933Z"
          stroke="#314E5D"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_2466_7">
          <rect width="108.947" height="100" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default IconSVG