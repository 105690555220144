export const staffList = [
  {
    img: "https://images.unsplash.com/photo-1629747387925-6905ff5a558a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MzN8fGJsYWNrJTIwd29tYW58ZW58MHwxfDB8fA%3D%3D&auto=format&fit=crop&w=1000&q=60",
    name: "Staff member name",
    title: "Staff member title",
  },
  {
    img: "https://images.unsplash.com/photo-1629747387925-6905ff5a558a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MzN8fGJsYWNrJTIwd29tYW58ZW58MHwxfDB8fA%3D%3D&auto=format&fit=crop&w=1000&q=60",
    name: "Staff member name",
    title: "Staff member title",
  },
  {
    img: "https://images.unsplash.com/photo-1629747387925-6905ff5a558a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MzN8fGJsYWNrJTIwd29tYW58ZW58MHwxfDB8fA%3D%3D&auto=format&fit=crop&w=1000&q=60",
    name: "Staff member name",
    title: "Staff member title",
  },
  {
    img: "https://images.unsplash.com/photo-1629747387925-6905ff5a558a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MzN8fGJsYWNrJTIwd29tYW58ZW58MHwxfDB8fA%3D%3D&auto=format&fit=crop&w=1000&q=60",
    name: "Staff member name",
    title: "Staff member title",
  },
  {
    img: "https://images.unsplash.com/photo-1629747387925-6905ff5a558a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MzN8fGJsYWNrJTIwd29tYW58ZW58MHwxfDB8fA%3D%3D&auto=format&fit=crop&w=1000&q=60",
    name: "Staff member name",
    title: "Staff member title",
  },
  {
    img: "https://images.unsplash.com/photo-1629747387925-6905ff5a558a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MzN8fGJsYWNrJTIwd29tYW58ZW58MHwxfDB8fA%3D%3D&auto=format&fit=crop&w=1000&q=60",
    name: "Staff member name",
    title: "Staff member title",
  },
  {
    img: "https://images.unsplash.com/photo-1629747387925-6905ff5a558a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MzN8fGJsYWNrJTIwd29tYW58ZW58MHwxfDB8fA%3D%3D&auto=format&fit=crop&w=1000&q=60",
    name: "Staff member name",
    title: "Staff member title",
  }
];