import React from "react";
import { Link } from "react-router-dom";
import "rsuite/styles/index.less";
import Footer from "../components/Footer";
import childrenStudying from "../resources/children-studying.jpg";

function Home() {
  return (
    <div className="text-tertiary flex flex-col items-center">
      <section className="relative lg:w-2/3">
        <p className=" z-10 top-10 left-8 md:top-56 md:left-24 w-1/2 lg:w-1/3 font-robotoExtraBold text-3xl md:text-5xl absolute text-outline text-white justify-self-start col-start-2">
          Every child deserves a safe home
        </p>
        <div className="col-start-2 col-end-6 md:border-x-4 border-primary">
          <img
            src={childrenStudying}
            alt="children studying at table with main focus on boy writing on printer paper near girl"
          />
        </div>
      </section>
      {/* block of abouts  */}
      <section className="panel-container">
        <div className="panel bg-staff-img">
          <Link to="/staff" className="panel-text">
            Our Staff and our Committee
          </Link>
        </div>
        <div className="panel bg-finger-painting">
          <Link to="what-we-do" className="panel-text">
            What we do
          </Link>
        </div>
        <div className="panel bg-house">
          <Link to="history-and-mission" className="panel-text">
            Our History and our Mission
          </Link>
        </div>
      </section>
			<Footer />
    </div>
  );
}

export default Home;
