import React from 'react'
import { Link } from 'react-router-dom'

function DonateBtn() {
	return (
		<Link to="/donate" className='text-white bg-primary flex justify-center items-center w-full font-ralewayBold cursor-pointer p-4 text-2xl md:hidden focus:no-underline focus:text-white'>
			Donate!
		</Link>
	)
}

export default DonateBtn