import React, { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ferndeansLogo from "../resources/ferndeansLogoWithText.png";
import emailjs from "@emailjs/browser";
import { useForm } from "react-hook-form";

function FoodForm(props) {
  const form = useRef();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const sendEmail = (data) => {
		let formData = {
        sender_name: data.senderName,
        sender_email: data.senderEmail,
        sender_contribution: data.senderContribution,
        sender_contribution_date: data.senderContributionDate,
        sender_message: data.senderMessage,
    };

		emailjs.init("lxl7HU_MF6AZiI6Xn");

    if (data.honeyPot === "") {
      emailjs
        .send("service_bmztdgr", "donation_form", formData)
        .then(
          (result) => {
            console.log(result.text);
            alert("Email sent!");
            form.current.reset();
          },
          (error) => {
            console.log(error.text);
          }
        );
    }
  };


  const FormHeader = (props) => {
    return (
      <div className="form-head-container">
        <FontAwesomeIcon
          icon="fa-solid fa-rectangle-xmark"
          className="form-icon"
          size="2x"
          onClick={() => {
            props.setOpenFood(!props.openFood);
          }}
        />
        <img src={ferndeansLogo} alt="Ferndean's logo" className="form-logo" />
        <h3 className="form-header">{props.header}</h3>
        <p className="form-intro">{props.info}</p>
      </div>
    );
  };

  return (
    <section
      className={
        props.openFood ? "form-block df-transition-props" : "form-block"
      }
    >
      <FormHeader
        openFood={props.openFood}
        setOpenFood={props.setOpenFood}
        header="Food donation"
        info="If you'd like to make a food donation to the home, please fill in this form and we'll get back to you:"
      />
      <form
        ref={form}
        onSubmit={handleSubmit(sendEmail)}
        className="form-container"
      >
        <div className="form-group">
          <label htmlFor="sender_name" className="form-label w-3/4">
            Name:
          </label>
          <input
            type="text"
            name="sender_name"
            aria-label="name"
            placeholder="Name..."
            className="form-input w-3/4"
            {...register("senderName", { required: true, maxlength: 30 })}
          />
        </div>
        {errors.senderName && <p className="form-error">Please enter a name</p>}
        <div className="form-group">
          <label htmlFor="sender_email" className="form-label w-3/4">
            Email:
          </label>
          <input
            type="email"
            name="sender_email"
            aria-label="email"
            placeholder="Email..."
            className="form-input w-3/4"
            {...register("senderEmail", {
              required: true,
              pattern:
                // eslint-disable-next-line no-useless-escape
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            })}
          />
        </div>
        {errors.senderEmail && (
          <p className="form-error">Please check the Email</p>
        )}
        <div className="form-group w-3/4 items-start">
          <label htmlFor="sender_contribution" className="form-label w-3/4">
            Type of contribution:
          </label>
          <select
            name="sender_contribution"
            className="form-select"
            {...register("senderContribution", { required: true })}
          >
            <option value="groceries">Groceries</option>
            <option value="a meal">Meal</option>
          </select>
          {errors.senderContribution && (
            <p className="form-error self-center">Please select an option</p>
          )}
        </div>
        <div className="form-group">
          <label
            htmlFor="sender_contribution_date"
            className="form-label w-3/4"
          >
            Date:
          </label>
          <input
            type="date"
            name="sender_contribution_date"
            aria-label="date"
            className="form-input w-3/4"
            {...register("senderContributionDate", { required: true })}
          />
          {errors.senderContributionDate && (
            <p className="form-error">Please enter a date</p>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="sender_message" className="form-label w-3/4">
            Notes:
          </label>
          <textarea
            name="sender_message"
            aria-label="message"
            placeholder="Your message..."
            className="form-input h-32 w-3/4"
            {...register("senderMessage", { required: true })}
          />
          {errors.senderMessage && (
            <p className="form-error">
              Please give us some information on your contribution
            </p>
          )}
        </div>
        <div className="form-group">
          <label
            htmlFor="honey_pot"
            className="form-label w-3/4 absolute invisible"
          >
            Enter something:
          </label>
          <input
            type="text"
            name="honey_pot"
            aria-label="honey_pot"
            placeholder="honey_pot..."
            className="form-input w-3/4 absolute invisible"
            {...register("honeyPot")}
          />
        </div>
        <input type="submit" value="Send" className="form-btn w-3/4" />
      </form>
      <p className="form-note">*Note: Please sign the donation book</p>
    </section>
  );
}

export default FoodForm;
