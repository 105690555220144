import React from 'react'

function MiniFooter() {
	return (
    <div>
      {/* contact block  */}
      <section className="home-contact-container">
        <h3 className="hc-header">Ferndean's Place Childrens' Home</h3>
        <div className="hc-info">
          <p className="hc-text">10 Hillcrest Drive</p>
          <p className="hc-text">Harriman Park, Point Fortin</p>
          <p className="hc-text">Trinidad and Tobago</p>
          <p className="hc-text">
            <strong className="hc-strong-text">Email:</strong>{" "}
            Ferndeansplacech@gmail.com
          </p>
          <p className="hc-text">
            <strong className="hc-strong-text">Phone:</strong> 1-868-648-3630
          </p>
        </div>
      </section>

      {/* now it works tech stamp */}
      <section className="designer-info">
        <p>
          Designed and built by{" "}
          <a
            href="https://nowitworkstech.com/"
            className="designer-info-link"
            target="_blank"
            rel="noreferrer"
          >
            Now IT Works Tech
          </a>
        </p>
      </section>
    </div>
  );
}

export default MiniFooter