import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useState } from "react";
import BigProjectCard from "../components/BigProjectCard";
import MiniFooter from "../components/MiniFooter";
import FinanceForm from "../components/FinanceForm";
import FoodForm from "../components/FoodForm";
import MiscForm from "../components/MiscForm";
import VolunteerForm from "../components/VolunteerForm";

function DonateHome() {
  const [openInfo, setOpenInfo] = useState(false);
  const [openFin, setOpenFin] = useState(false);
  const [openFood, setOpenFood] = useState(false);
  const [openVol, setOpenVol] = useState(false);
  const [openMisc, setOpenMisc] = useState(false);

  return (
    <section className="donate">
      {/* card */}
      <BigProjectCard
        openFin={openFin}
        setOpenFin={setOpenFin}
        openInfo={openInfo}
        setOpenInfo={setOpenInfo}
      />

      <FinanceForm openFin={openFin} setOpenFin={setOpenFin} />
      <FoodForm openFood={openFood} setOpenFood={setOpenFood} />
      <MiscForm openMisc={openMisc} setOpenMisc={setOpenMisc} />
      <VolunteerForm openVol={openVol} setOpenVol={setOpenVol} />

      <section className="donation-options">
        <h3 className="donation-header">How would you like to donate?</h3>
        <div className="options-list">
          <div
            className="option-bg"
            onClick={() => {
              setOpenFin(!openFin);
            }}
          >
            <div className="option-container">
              <FontAwesomeIcon
                className="option-icon"
                icon="fa-solid fa-hand-holding-dollar"
                size="4x"
              />
              <p className="option-title">Finance</p>
            </div>
          </div>
          <div
            className="option-bg"
            onClick={() => {
              setOpenFood(!openFood);
            }}
          >
            <div className="option-container">
              <FontAwesomeIcon
                className="option-icon"
                icon="fa-solid fa-shopping-cart"
                size="4x"
              />
              <p className="option-title">Food</p>
            </div>
          </div>
          <div
            className="option-bg"
            onClick={() => {
              setOpenVol(!openVol);
            }}
          >
            <div className="option-container">
              <FontAwesomeIcon
                className="option-icon"
                icon="fa-solid fa-user-clock"
                size="4x"
              />
              <p className="option-title">Your time</p>
            </div>
          </div>
          <div
            className="option-bg"
            onClick={() => {
              setOpenMisc(!openMisc);
            }}
          >
            <div className="option-container">
              <FontAwesomeIcon
                className="option-icon"
                icon="fa-solid fa-ellipsis"
                size="4x"
              />
              <p className="option-title">Other</p>
            </div>
          </div>
        </div>
      </section>

      <MiniFooter />
    </section>
  );
}

export default DonateHome;
