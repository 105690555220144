import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ferndeansLogo from "../resources/ferndeansLogoWithText.png";

function FinanceForm(props) {

  const FormHeader = () => {
    return (
      <div className="form-head-container">
        <FontAwesomeIcon
          icon="fa-solid fa-rectangle-xmark"
          className="form-icon"
          size="2x"
          onClick={() => {
            props.setOpenFin(!props.openFin);
          }}
        />
        <img src={ferndeansLogo} alt="Ferndean's logo" className="form-logo" />
        <h3 className="form-header">{props.header}</h3>
        <p className="form-intro">{props.info}</p>
      </div>
    );
  };

  return (
    <section
      className={
        props.openFin ? "form-block df-transition-props" : "form-block"
      }
    >
      <FormHeader
        openFin={props.openFin}
        setOpenFin={props.setOpenFin}
        header="Financial donation"
        info="If you'd like to make a financial donation to the home, you have the
        following two options:"
      />
      <ol className="list-decimal">
        <li className="df-list-item">
          <h4 className="df-header">Give us cash!</h4>
          <p className="form-text">
            You can bring your cash donation to the home on{" "}
            <strong>any weekday 10am-3pm</strong> and sign our donation book.
          </p>
        </li>
        <li className="df-list-item">
          <h4 className="df-header">Make a bank transfer:</h4>
          <div className="form-text-container">
            <h5 className="form-label">Account name:</h5>
            <p className="form-text">Ferndean's Place Childrens' Home</p>
          </div>
          <div className="form-text-container">
            <h5 className="form-label">Account number:</h5>
            <p className="form-text">100085010610411</p>
          </div>
          <div className="form-text-container">
            <h5 className="form-label">Payment/statement reference:</h5>
            <p className="form-text">donation</p>
            <p className="form-text">(for our big project use 'bigproject' )</p>
          </div>
          <div className="form-text-container">
            <h5 className="form-label">Bank:</h5>
            <p className="form-text">RBC Royal Bank T&T</p>
            <p className="form-text">Port of Spain</p>
            <p className="form-text">Trinidad</p>
          </div>
          <div className="form-text-container">
            <h5 className="form-label">
              Bank swift code(also called bank identification code/swift):
            </h5>
            <p className="form-text">RBTTTTPX</p>
          </div>
        </li>
      </ol>
      <p className="form-note">
        *Note: Please pay all fees associated with the transfer, we are unable
        to pay the bank fees associated with international transfers
      </p>
    </section>
  );
}

export default FinanceForm;
