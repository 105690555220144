import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { useForm } from "react-hook-form";

export const ContactForm = () => {
  const form = useRef();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_bmztdgr",
        "contact_form",
        form.current,
        "lxl7HU_MF6AZiI6Xn"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Email sent!");
          form.current.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div className="cf-container">
      <form
        ref={form}
        onSubmit={handleSubmit(sendEmail)}
        className="form-container"
      >
        <h3 className="form-header">Get in touch!</h3>
        <div className="contact-col">
          <div className="form-group">
            <label
              htmlFor="sender_name"
              className="form-label md:w-3/4 self-start"
            >
              Name:
            </label>
            <input
              type="text"
              name="sender_name"
              aria-label="name"
              placeholder="Name..."
              className="form-input w-full md:w-auto md:m-0"
              {...register("senderName", { required: true, maxlength: 30 })}
            />
            {errors.senderName && (
              <p className="form-error">Please enter a name</p>
            )}
          </div>

          <div className="form-group ">
            <label
              htmlFor="sender_email"
              className="form-label md:w-3/4 self-start"
            >
              Email:
            </label>
            <input
              type="email"
              name="sender_email"
              aria-label="email"
              placeholder="Email..."
              className="form-input w-full md:w-auto md:m-0"
              {...register("senderEmail", {
                required: true,
                pattern:
                  // eslint-disable-next-line no-useless-escape
                  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              })}
            />
            {errors.senderEmail && (
              <p className="form-error">Please check the Email</p>
            )}
          </div>
        </div>

        <div className="form-group mt-4">
          <label
            htmlFor="sender_message"
            className="form-label w-3/4 md:self-start"
          >
            Message:
          </label>
          <textarea
            name="sender_message"
            aria-label="message"
            placeholder="Message..."
            className="form-input h-32 w-3/4 md:w-full md:m-0"
            {...register("senderMessage", { required: true })}
          />
          {errors.senderMessage && (
            <p className="form-error">Please add a message</p>
          )}
        </div>
        <div className="form-group">
          <label
            htmlFor="honey_pot"
            className="form-label w-3/4 absolute invisible"
          >
            Enter something:
          </label>
          <input
            type="text"
            name="honey_pot"
            aria-label="honey_pot"
            placeholder="honey_pot..."
            className="form-input w-3/4 absolute invisible"
            {...register("honeyPot")}
          />
        </div>
        <input type="submit" value="Send" className="form-btn w-3/4" />
      </form>
    </div>
  );
};
