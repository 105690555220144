import React from 'react'
import { Link } from 'react-router-dom';
import childWithCaregiver from "../resources/caregiver-and-young-child.jpg";

function Footer() {
	return (
    <div className='border-tertiary border-4'>
      {/* donate block */}
      <section className="home-donate-container">
        <div className="home-donate">
          <h2 className="home-donate-item p-4 text-white text-6xl md:text-4xl lg:text-5xl font-ralewayExtraBold underline">
            Join Us!
          </h2>
          <h4 className="home-donate-item text-2xl md:text-lg  lg:text-2xl text-white p-4 font-robotoRegular">
            Help provide a safer childhoods
          </h4>
          <div className="home-donate-item home-donate-btns">
            <button className="home-donate-btn">
              <Link to="/donate" className="home-donate-link">
                Donate
              </Link>
            </button>
            <button className="home-donate-btn">
              <Link to="/donate" className="home-donate-link">
                Volunteer
              </Link>
            </button>
          </div>
        </div>
        <img
          src={childWithCaregiver}
          alt="child sitting at a table and caregiver sitting on the floor"
          className="hidden md:col-start-2 md:col-end-5 md:block h-full"
        />
      </section>

      {/* contact block  */}
      <section className="home-contact-container">
        <h3 className="hc-header">Ferndean's Place Childrens' Home</h3>
        <div className="hc-info">
          <p className="hc-text">10 Hillcrest Drive</p>
          <p className="hc-text">Harriman Park, Point Fortin</p>
          <p className="hc-text">Trinidad and Tobago</p>
          <p className="hc-text">
            <strong className="hc-strong-text">Email:</strong>{" "}
            Ferndeansplacech@gmail.com
          </p>
          <p className="hc-text">
            <strong className="hc-strong-text">Phone:</strong> 1-868-648-3630
          </p>
        </div>
      </section>

      {/* now it works tech stamp */}
      <section className="designer-info">
        <p>
          Designed and built by{" "}
          <a
            href="https://nowitworkstech.com/"
            className="designer-info-link"
            target="_blank"
            rel="noreferrer"
          >
            Now IT Works Tech
          </a>
        </p>
      </section>
    </div>
  );
}

export default Footer