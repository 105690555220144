export const committeeList = [
  {
    img: "https://images.unsplash.com/photo-1591848478625-de43268e6fb8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8YmxhY2slMjBwZW9wbGV8ZW58MHwxfDB8fA%3D%3D&auto=format&fit=crop&w=900&q=60",
    name: "committee member name",
    title: "committee member title",
  },
  {
    img: "https://images.unsplash.com/photo-1591848478625-de43268e6fb8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8YmxhY2slMjBwZW9wbGV8ZW58MHwxfDB8fA%3D%3D&auto=format&fit=crop&w=900&q=60",
    name: "committee member name",
    title: "committee member title",
  },
  {
    img: "https://images.unsplash.com/photo-1591848478625-de43268e6fb8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8YmxhY2slMjBwZW9wbGV8ZW58MHwxfDB8fA%3D%3D&auto=format&fit=crop&w=900&q=60",
    name: "committee member name",
    title: "committee member title",
  },
  {
    img: "https://images.unsplash.com/photo-1591848478625-de43268e6fb8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8YmxhY2slMjBwZW9wbGV8ZW58MHwxfDB8fA%3D%3D&auto=format&fit=crop&w=900&q=60",
    name: "committee member name",
    title: "committee member title",
  }
];
