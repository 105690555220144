import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

function WhatWeDo() {
  return (
		<div>
      <Header title="What we do" />
			<div className="flex flex-col items-center">
      <div className="wwd-container">
        <p>
          Cupcake ipsum dolor sit amet sugar plum toffee fruitcake. Fruitcake
          cake oat cake jelly beans croissant cake chocolate bar lollipop.
          Topping sesame snaps donut tootsie roll I love jujubes. Pudding powder
          marzipan cotton candy gingerbread bonbon gummi bears I love chupa
          chups.
        </p>
        <ul className="wwd-ul">
          <li>cupcake</li>
          <li>cupcake</li>
          <li>cupcake</li>
          <li>cupcake</li>
          <li>cupcake</li>
        </ul>
        <p>
          Topping I love tiramisu marzipan ice cream sweet I love ice cream.
          Pastry toffee gingerbread lollipop I love cookie I love cotton candy.
          Cake I love lollipop sweet I love gummies oat cake.
        </p>
        <p>
          Marzipan icing oat cake carrot cake cheesecake jelly-o muffin. I love
          chupa chups wafer pudding liquorice croissant chocolate gummi bears.
          Sweet roll toffee sesame snaps caramels toffee tart marshmallow
          marzipan.
        </p>
      </div>

      
    </div>
		<Footer />
		</div>
    
  );
}

export default WhatWeDo;
