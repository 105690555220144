import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { committeeList } from "../resources/committeeList";
import { staffList } from "../resources/staffList";

function Staffing() {
  return (
    <section>
      <Header title="Our Staff" />
      <div className="lg:flex flex-col items-center">
        <p className="avatar-intro">
          Tiramisu wafer danish tiramisu brownie bonbon gingerbread. Lollipop I
          love candy canes topping gingerbread icing I love chocolate sugar
          plum. Lemon drops halvah sugar plum I love apple pie apple pie pudding
          toffee I love. Bonbon sesame snaps liquorice sweet roll sugar plum
          cotton candy I love chocolate cake.
        </p>
        <div className="avatars">
          {staffList.map((person, idx) => {
            return (
              <div key={idx} className="avatar-container">
                <img
                  src={person.img}
                  alt={person.name}
                  className="avatar-img"
                />
                <h4 className="avatar-name">{person.name}</h4>
                <p className="avatar-title">{person.title}</p>
              </div>
            );
          })}
        </div>
      </div>

      <Header title="Our Committee" />
      <div className="lg:flex flex-col items-center">
        <p className="avatar-intro">
          Tiramisu wafer danish tiramisu brownie bonbon gingerbread. Lollipop I
          love candy canes topping gingerbread icing I love chocolate sugar
          plum. Lemon drops halvah sugar plum I love apple pie apple pie pudding
          toffee I love. Bonbon sesame snaps liquorice sweet roll sugar plum
          cotton candy I love chocolate cake.
        </p>
        <div className="avatars">
          {committeeList.map((person, idx) => {
            return (
              <div key={idx} className="avatar-container">
                <img
                  src={person.img}
                  alt={person.name}
                  className="avatar-img"
                />
                <h4 className="avatar-name">{person.name}</h4>
                <p className="avatar-title">{person.title}</p>
              </div>
            );
          })}
        </div>
      </div>

      <Footer />
    </section>
  );
}

export default Staffing;
