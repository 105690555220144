import React, { useState } from "react";
import ferndeansLogo from "../resources/ferndeansLogoWithText.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import DonateBtn from "./DonateBtn";

function Navigation() {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [toggleAbout, setToggleAbout] = useState(false);

	let navigate = useNavigate()

  const closeMenus = () => {
    setToggleMenu(false);
    setToggleAbout(false);
  };

  return (
    <div className=" transition-[height] border-b-4 border-primary pb-2 sticky top-0 w-screen z-30 bg-white md:flex flex-row justify-between">
      <DonateBtn />
      <div className="grid grid-cols-5 md:grid-cols-2 py-2 ">
        <img
          src={ferndeansLogo}
          alt="Ferndean's logo"
          className="col-start-2 col-end-5 md:col-start-1 md:col-end-3 justify-self-center md:h-28 cursor-pointer"
          onClick={() => {
            navigate("/");
          }}
        />
        <FontAwesomeIcon
          icon={toggleMenu ? "fa-solid fa-times" : "fa-solid fa-bars"}
          className="md:hidden transition-[display] justify-self-center self-center text-primary cursor-pointer"
          size="2xl"
          onClick={() => {
            setToggleMenu(!toggleMenu);
          }}
        />
      </div>
      <nav
        className={`flex p-2 font-ralewayBold lg:text-2xl lg:font-ralewaySemiBold md:items-center mr-10 ${
          toggleMenu
            ? "flex-col text-lg md:justify-center md:text-xl nav-transition-props"
            : "nav-transition translate-y-[-192px] opacity-0 invisible absolute md:translate-y-0 md:visible md:opacity-100 md:relative md:flex justify-center text-xl flex-row"
        }`}
      >
        <ul className="nav-menu">
          <li className="nav-item">
            <Link
              to="/"
              className="nav-active"
              onClick={() => {
                closeMenus();
              }}
            >
              Home
            </Link>
          </li>

          <li
            className={`nav-item md:items-center hover:text-tertiary ${
              toggleAbout ? "text-tertiary" : "text-primary"
            }`}
            onClick={() => {
              setToggleAbout(!toggleAbout);
            }}
          >
            About
            <FontAwesomeIcon
              icon={
                toggleAbout
                  ? "fa-solid fa-caret-down"
                  : "fa-solid fa-caret-right"
              }
              className="ml-2"
            />
            <ul
              title="about-menu"
              className={
                toggleAbout
                  ? "md:absolute md:rounded md:border-primary md:border md:mt-2 md:py-4 md:space-y-2 lg:space-y-8 lg:p-8 lg:text-xl bg-white px-6 py-2 font-ralewayRegular text-md flex flex-col text-primary"
                  : "hidden"
              }
            >
              <li>
                <Link
                  to="/what-we-do"
                  className="nav-sub-menu"
                  onClick={() => {
                    closeMenus();
                  }}
                >
                  What we do
                </Link>
              </li>
              <li>
                <Link
                  to="/history-and-mission"
                  className="nav-sub-menu"
                  onClick={() => {
                    closeMenus();
                  }}
                >
                  Our History and our Mission
                </Link>
              </li>
              <li>
                <Link
                  to="/staff-and-committee"
                  className="nav-sub-menu"
                  onClick={() => {
                    closeMenus();
                  }}
                >
                  Our Staff and our Committee
                </Link>
              </li>
            </ul>
          </li>

          <li className="nav-item">
            <Link
              to="/contact"
              className="nav-active"
              onClick={() => {
                closeMenus();
              }}
            >
              Contact
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/donate"
              className="text-secondary nav-active "
              onClick={() => {
                closeMenus();
              }}
            >
              Donate Now!
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default Navigation;
