import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import {
  faPhone,
  faBars,
  faMapMarker,
  faEnvelope,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { Route, Routes } from "react-router";
import Home from "./pages/Home";
import History from "./pages/History";
import WhatWeDo from "./pages/WhatWeDo";
import Staffing from "./pages/Staffing";
import DonateHome from "./pages/DonateHome";
import Contact from "./pages/Contact";
import Navigation from "./components/Navigation";

library.add(fas, faPhone, faBars, faMapMarker, faEnvelope, faTimes);

function App() {
  return (
    <div className="bg-white">
      <Navigation />
			<div>
				<Routes>
				 <Route path="/" element={<Home />} />
				 <Route path="/history-and-mission" element={<History />} />
				 <Route path="/what-we-do" element={<WhatWeDo />} />
				 <Route path="/staff-and-committee" element={<Staffing />} />
				 <Route path="/donate" element={<DonateHome />} />
				 <Route path="/contact" element={<Contact />} />
			</Routes>
			</div>
			
    </div>
  );
}

export default App;
