import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Progress } from "rsuite";
import IconSVG from "../components/IconSVG";
import ferndeansLogo from "../resources/ferndeansLogoWithText.png";

function BigProjectCard(props) {
	return (
    <section className="donation-card-container">
      <h3 className="donation-header text-2xl tracking-widest">
        Our current BIG project!
      </h3>
      <div
        className={
          props.openInfo ? "donation-card dc-transition-props" : "donation-card"
        }
        onClick={() => {
          props.setOpenInfo(!props.openInfo);
        }}
      >
        <h3 className="dc-header">Upgrade facilities</h3>
        <FontAwesomeIcon
          icon="fa-solid fa-circle-arrow-right"
          size="2xl"
          className="dc-icon"
          aria-label="view info"
        />
        <IconSVG />
        <div className="dc-target-container">
          <h5 className="dc-target-header">Target:</h5>
          <p className="dc-target-amount">TT$150,000</p>
        </div>
      </div>
      <div
        className={
          props.openInfo
            ? "dc-info-container dci-transition-props "
            : "dc-info-container"
        }
      >
        <FontAwesomeIcon
          icon="fa-solid fa-rectangle-xmark"
          className="form-icon"
          size="2x"
          onClick={() => {
            props.setOpenInfo(!props.openInfo);
          }}
        />
        <img
          src={ferndeansLogo}
          alt="Ferndean's logo"
          className="dc-info-img"
        />
        <div className="dc-info-progress">
          <h4>TT$90,000 raised out of TT$150,000</h4>
          <Progress.Line percent={65} showInfo={false} strokeColor="#47A7B2" />
        </div>
        <p className="dc-info-para">
          We’re raising money for Lorem ipsum dolor sit amet consectetur. Sed
          urna pharetra iaculis cursus sem placerat id in. Sit mollis eget enim
          eget velit. Lacus et tempus tempus in varius nam. Eget vestibulum et
          massa iaculis nibh interdum dictum facilisis nibh. Vestibulum ut
          ullamcorper et adipiscing vestibulum ante felis. Pulvinar non tellus
          duis venenatis eu felis. Sociis imperdiet vitae nunc id placerat non
          eget vulputate et. Sit pharetra elit leo aenean scelerisque faucibus
          feugiat. Pulvinar proin amet velit turpis orci. Egestas eget tincidunt
          dolor massa. Ornare donec tincidunt sit a adipiscing vitae volutpat
          sed. Bibendum arcu egestas sollicitudin diam.{" "}
        </p>
        <button
          className="dc-info-btn-one"
          onClick={() => {
            props.setOpenFin(!props.openFin);
          }}
        >
          Contribute
        </button>
      </div>
    </section>
  );
}

export default BigProjectCard