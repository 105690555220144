import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";


function History() {
  return (
    <div className="md:divide-y-2 divide-tertiary md:flex flex-col items-center">
      {/* history */}
      <section className=" md:flex flex-col md:border-x-4 border-primary ">
        <Header title="Our History" />
        <div className="history-info self-center md:w-2/3">
          <p>
            Cupcake ipsum dolor sit amet sugar plum toffee fruitcake. Fruitcake
            cake oat cake jelly beans croissant cake chocolate bar lollipop.
            Topping sesame snaps donut tootsie roll I love jujubes. Pudding
            powder marzipan cotton candy gingerbread bonbon gummi bears I love
            chupa chups.
          </p>
          <p>
            Topping I love tiramisu marzipan ice cream sweet I love ice cream.
            Pastry toffee gingerbread lollipop I love cookie I love cotton
            candy. Cake I love lollipop sweet I love gummies oat cake.
          </p>
          <p>
            Marzipan icing oat cake carrot cake cheesecake jelly-o muffin. I
            love chupa chups wafer pudding liquorice croissant chocolate gummi
            bears. Sweet roll toffee sesame snaps caramels toffee tart
            marshmallow marzipan.
          </p>
          <p>
            Cookie soufflé biscuit halvah pudding topping I love lemon drops
            dragée. Gummi bears carrot cake ice cream I love I love. Candy canes
            pudding cookie muffin liquorice I love donut jujubes.
          </p>
          <p>
            Gummies liquorice chupa chups cookie muffin cookie gummi bears bear
            claw. Tootsie roll marzipan icing cupcake sweet roll jelly beans
            jelly beans. I love cheesecake I love I love chocolate cake cake
            apple pie.
          </p>
          <p>
            Tiramisu wafer danish tiramisu brownie bonbon gingerbread. Lollipop
            I love candy canes topping gingerbread icing I love chocolate sugar
            plum. Lemon drops halvah sugar plum I love apple pie apple pie
            pudding toffee I love. Bonbon sesame snaps liquorice sweet roll
            sugar plum cotton candy I love chocolate cake.
          </p>
          <p>
            Sugar plum macaroon biscuit I love cupcake shortbread. Croissant
            lemon drops chocolate apple pie toffee cotton candy. Sweet roll
            danish sesame snaps tiramisu oat cake.
          </p>
          <p>
            Cotton candy cake powder jujubes jelly pie bear claw icing
            gingerbread. Lollipop sesame snaps chocolate cake pudding cupcake.
            Candy jelly liquorice apple pie jelly-o bear claw apple pie biscuit.
          </p>
          <p>
            Marshmallow pudding jelly beans bear claw I love dragée powder jelly
            sesame snaps. Jujubes jelly beans lemon drops dessert candy gummies
            tootsie roll. Oat cake I love liquorice cupcake cotton candy.
          </p>
          <p>
            Cupcake pudding topping cookie chocolate cake marshmallow cake.
            Topping halvah wafer chocolate bar wafer pudding brownie fruitcake.
            Marshmallow gingerbread pie ice cream chocolate gingerbread. Sweet
            roll bear claw I love lemon drops sugar plum I love cupcake.
          </p>
          <p>
            Cotton candy lemon drops I love tiramisu I love sweet roll gummies I
            love candy. I love tiramisu brownie chocolate cake topping marzipan
            chocolate cake. Chocolate cotton candy ice cream tootsie roll bear
            claw I love cheesecake I love.
          </p>
          <p>
            Lemon drops soufflé apple pie chocolate pastry jujubes. Jelly-o
            jelly-o biscuit carrot cake gummi bears. Dragée jelly beans
            chocolate cake lemon drops chocolate cake gingerbread. Sesame snaps
            chocolate bar I love apple pie pie croissant jelly cake.
          </p>
        </div>
      </section>
      <img
        src="https://images.unsplash.com/photo-1533029764620-40021530b1aa?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8YmxhY2slMjBnaXJsJTIwbGlicmFyeXxlbnwwfDB8MHx8&auto=format&fit=crop&w=900&q=60"
        alt="child looking through bookshelf"
        className="history-img"
      />
      {/* founder */}
      <section className="founder-container md:border-x-4 border-primary">
        <h2 className="history-subheader text-primary md:w-2/3">Our Founder</h2>
        <div className="founder-info-container  md:w-2/3">
          <div className="founder-img-container">
            <img
              src="https://images.unsplash.com/photo-1531475800102-75726f18e780?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NjJ8fG9sZCUyMHdvbWFufGVufDB8MXwwfHw%3D&auto=format&fit=crop&w=900&q=60"
              alt="older black woman smiling"
              className="founder-img"
            />
            <h3 className="founder-img-title">Ruth Elcock-Small</h3>
          </div>
          <p className="founder-info">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Dictum
            fusce ut placerat orci. Sed felis eget velit aliquet sagittis id
            consectetur. Tellus orci ac auctor augue. Lacus laoreet non
            curabitur gravida. Justo nec ultrices dui sapien eget mi proin.
            Convallis convallis tellus id interdum velit laoreet id. Nulla
            posuere sollicitudin aliquam ultrices sagittis orci a scelerisque
            purus. Viverra accumsan in nisl nisi.
          </p>
        </div>
      </section>
      {/* mission */}
      <div className="flex flex-col items-center border-x-4 border-y-4 border-tertiary">
        <img
          src="https://images.unsplash.com/photo-1610500796385-3ffc1ae2f046?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NTUyfHxoYXBweSUyMGNoaWxkcmVufGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=900&q=60"
          alt="children sitting on the floor studying together"
          className="history-img"
        />
        <section className="mission-container md:w-2/3 ">
          <h2 className="history-subheader">Our Mission</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Dictum
            fusce ut placerat orci. Sed felis eget velit aliquet sagittis id
            consectetur. Tellus orci ac auctor augue. Lacus laoreet non
            curabitur gravida. Justo nec ultrices dui sapien eget mi proin.
            Convallis convallis tellus id interdum velit laoreet id. Nulla
            posuere sollicitudin aliquam ultrices sagittis orci a scelerisque
            purus. Viverra accumsan in nisl nisi.
          </p>
        </section>
      </div>
			
      <Footer />
    </div>
  );
}

export default History;
