import React from "react";
import { ContactForm } from "../components/ContactForm";
import Footer from "../components/Footer";
import Header from "../components/Header";

function Contact() {
  return (
    <section>
      <Header title="Contact" />
      <section className="flex flex-col md:flex-row md:max-h-128">
        <ContactForm />
        <img
          src="https://images.unsplash.com/photo-1518780664697-55e3ad937233?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8MXx8fGVufDB8fHx8&auto=format&fit=crop&w=1600&q=60"
          alt="small red house on a hill to represent Ferndean's Place Childrens' Home"
					className="md:w-1/2"
        />
      </section>
      <div class="mapouter">
        <div class="gmap_canvas">
          <iframe
            title="Ferndean's location"
            width="100%"
            height="500"
            id="gmap_canvas"
            src="https://maps.google.com/maps?q=10%20Hillcrest%20Drive,%20Point%20Fortin,%20Trinidad%20and%20Tobago&t=&z=13&ie=UTF8&iwloc=&output=embed"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
          ></iframe>
        </div>
      </div>
			<Footer />
    </section>
  );
}

export default Contact;
